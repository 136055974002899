import axios from "axios";
import { getAuth } from "firebase/auth";

const apiPath = process.env.REACT_APP_API_PATH;
const auth = getAuth();

export const deleteUser = async (userId) => {
  try {
    const token = await auth.currentUser?.getIdToken(true);
    const response = await axios.delete(`${apiPath}/admin/users/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createUser = async ({ email, firstName, lastName, company }) => {
  try {
    if (!email || !firstName || !lastName) {
      throw new Error("required field missing");
    }
    const token = await auth.currentUser?.getIdToken(true);

    console.log("---TOKEN---");
    const response = await axios.post(
      `${apiPath}/admin/users`,
      {
        email,
        firstName,
        lastName,
        company,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!response.data?.password) {
      throw new Error("invalid response (password missing)");
    }
    return response.data?.password;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
