import { useContext } from "react";
import { Route, useLocation } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { AuthContext } from "../context/Auth";
import { Redirect } from "react-router";
import urlencode from "urlencode";
import PageNotFound from "../pages/PageNotFound";

const RouteWithLayout = ({
  children,
  layoutComponent,
  auth = "public",
  ...rest
}) => {
  const LayoutComponent = layoutComponent || MainLayout;
  const { user, isAuthenticating, isInitializing, isAdmin } =
    useContext(AuthContext);
  const location = useLocation();

  if (auth === "admin" && !isAdmin) {
    return (
      <LayoutComponent>
        <PageNotFound />
      </LayoutComponent>
    );
  }

  return (
    <Route {...rest}>
      {isInitializing ? (
        <div>Initializing</div>
      ) : isAuthenticating ? (
        <div>Authenticating...</div>
      ) : auth === "public" ||
        (user && auth === "private") ||
        (isAdmin && auth === "admin") ? (
        <LayoutComponent>{children}</LayoutComponent>
      ) : (
        <Redirect to={`/signin?redirect_url=${urlencode(location.pathname)}`} />
      )}
    </Route>
  );
};

export default RouteWithLayout;
