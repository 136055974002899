import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@mui/material";
import dayjs from "dayjs";

const LogList = ({ logs }) => {
  const logList = logs.map((log) => {
    return (
      <TableRow key={log.timestamp}>
        <TableCell>
          {dayjs(log.timestamp).format("DD.MM.YYYY HH:mm:ss")}
        </TableCell>
        <TableCell>
          {log.status === "success"
            ? "Import erfolgreich"
            : "Import fehlgeschlagen"}
        </TableCell>
        <TableCell>{log.error || ""}</TableCell>
      </TableRow>
    );
  });
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Datum/Uhrzeit</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{logList}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default LogList;
