import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  LinearProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createUser } from "../../services/api/user";
import { Typography } from "@mui/material";
import urlencode from "urlencode";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  dialogContent: {
    width: 480,
    minWidth: 480,
  },
}));

const SendCredentialsTab = ({
  email,
  firstName,
  lastName,
  company,
  password,
}) => {
  return (
    <>
      <Grid item container xs={12}>
        <Typography>
          Der Benutzer <strong>{`${firstName} ${lastName}`}</strong> wurde
          erfolgreich angelegt. Wählen Sie{" "}
          <strong>Anmeldedaten versenden</strong>, um das initiale Passwort per
          Mail zu verschicken.
        </Typography>
        <Typography>
          Initiales Passwort:{" "}
          <code>
            <strong>{password}</strong>
          </code>
          <Tooltip title="Passwort kopieren">
            <IconButton>
              <ContentCopyIcon
                onClick={() => {
                  navigator.clipboard.writeText(password);
                }}
              />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>
    </>
  );
};

const LoadingView = () => {
  return (
    <Grid item xs={12}>
      <LinearProgress />

      <Typography>
        Benutzer wird angelegt, bitte haben Sie einen Moment Geduld...
      </Typography>
    </Grid>
  );
};

const NewUserForm = ({
  open = false,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  const [generatedPassword, setGeneratedPassword] = useState(null);
  const [generating, setGenerating] = useState(false);
  const [error, setError] = useState(null);
  const generateUser = async ({ firstName, lastName, email, company }) => {
    try {
      setGenerating(true);
      const password = await createUser({
        firstName,
        lastName,
        email,
        company,
      });
      setGeneratedPassword(password);
      onSuccess();
    } catch (err) {
      setError(err.message);
    } finally {
      setGenerating(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      company: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Feld erforderlich"),
      lastName: Yup.string().required("Feld erforderlich"),
      email: Yup.string()
        .email("Ungültiges E-Mail Format")
        .required("Feld erforderlich"),
    }),
    validateOnChange: true,
    onSubmit: (values) => {
      generateUser(values);
    },
  });

  const classes = useStyles();

  const formView = (
    <>
      <Grid item xs={6}>
        <TextField
          error={
            formik.touched.firstName && formik.errors.firstName ? true : false
          }
          helperText={formik.touched.firstName ? formik.errors.firstName : ""}
          required
          className={classes.textField}
          id="firstName"
          value={formik.values.firstName}
          label="Vorname"
          variant="standard"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          error={
            formik.touched.lastName && formik.errors.lastName ? true : false
          }
          helperText={formik.touched.lastName ? formik.errors.lastName : ""}
          required
          className={classes.textField}
          id="lastName"
          value={formik.values.lastName}
          label="Nachname"
          variant="standard"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          error={formik.touched.email && formik.errors.email ? true : false}
          helperText={formik.touched.email ? formik.errors.email : ""}
          required
          className={classes.textField}
          id="email"
          value={formik.values.email}
          label="E-Mail"
          variant="standard"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          className={classes.textField}
          id="company"
          value={formik.values.company}
          label="Firma"
          variant="standard"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
    </>
  );
  const resultView = (
    <>
      <Grid item xs={12}>
        <Typography>Passwort: {generatedPassword}</Typography>
      </Grid>
    </>
  );

  const errorView = (
    <>
      <Grid item xs={12}>
        <Typography>Es ist ein Fehler aufgetreten: {error}</Typography>
      </Grid>
    </>
  );

  return (
    <Dialog open={open}>
      <DialogTitle>Neuer Benutzer</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} className={classes.dialogContent}>
          {error ? (
            errorView
          ) : generating ? (
            <LoadingView />
          ) : generatedPassword ? (
            <SendCredentialsTab
              firstName={formik.values.firstName}
              lastName={formik.values.lastName}
              email={formik.values.email}
              password={generatedPassword}
            />
          ) : (
            formView
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={generating}
          onClick={() => {
            if (
              generatedPassword &&
              !window.confirm(
                "Das initiale Passwort wird nur dieses eine Mal angezeigt! Sind Sie sicher, dass Sie das Fenster schließen wollen?"
              )
            ) {
              return;
            }
            onClose();
            formik.resetForm();
            setGenerating(false);
            setGeneratedPassword(null);
            setError(null);
          }}
        >
          Schließen
        </Button>
        <Button
          disabled={
            generating || !formik.dirty || Object.keys(formik.errors).length > 0
          }
          onClick={() => {
            if (generatedPassword) {
              window.open(
                `mailto:${formik.values.email}?subject=${urlencode(
                  "Ihre Anmeldedaten für die Kolumbarium Verwaltungssoftware"
                )}&body=${urlencode(
                  `Liebe/r ${formik.values.firstName} ${formik.values.lastName},
hier finden Sie Ihre Anmeldedaten für die Kolumbarium Verwaltungssoftware:
Passwort: ${generatedPassword}
                  `
                )}
                `
              );
            } else {
              formik.handleSubmit();
            }
          }}
        >
          {generatedPassword ? "Anmeldedaten versenden" : "Speichern"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewUserForm;
