import { useState, useRef, createContext, useContext } from "react";
import AlertDialog from "../components/AlertDialog";

export const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertContextProvider = ({ children }) => {
  const awaitingPromiseRef = useRef({
    resolve: () => {},
    reject: () => {},
  });
  const [confirmationState, setConfirmationState] = useState(null);

  const openAlert = ({ title, description }) => {
    setConfirmationState({ title, description });
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };
  return (
    <>
      <AlertContext.Provider value={{ openAlert }}>
        {children}
      </AlertContext.Provider>
      <AlertDialog
        open={confirmationState ? true : false}
        title={confirmationState?.title}
        description={confirmationState?.description}
        onSubmit={handleSubmit}
        onClose={handleClose}
        catchOnCancel={confirmationState?.catchOnCancel}
      />
    </>
  );
};
