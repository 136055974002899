import { AppBar, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthButton from "./AuthButton";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
const useStyles = makeStyles((theme) => ({
  appbar: (props) => ({
    width: `calc(100% - ${props.offset}px)`,
    marginLeft: props.offset,
    backgroundColor: "#5c5b61"
  }),

  toolbar: {
    backgroundColor: "#5c5b61"
  },
  burgerIcon: {
    color: "#ffffff"
  }
}));

const MainAppBar = ({ offset = 0, pageTitle, burgerToggle }) => {
  const classes = useStyles({ offset });
  return (
    <AppBar
      position="fixed"
      className={classes.appbar}
      elevation={0}
      color="primary"
    >
      <Toolbar className={classes.toolbar}>
        <IconButton onClick={burgerToggle(true)}>
          <MenuIcon className={classes.burgerIcon} />
        </IconButton>
        <AuthButton />
      </Toolbar>
    </AppBar>
  );
};

export default MainAppBar;
