import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import MainNav from "./MainNav";
import { menuItems } from "./menu-items";
import MainAppBar from "./MainAppBar";
import { useLocation } from "react-router";
import { circularProgressClasses } from "@mui/material";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  header: {
    width: "100%",
  },

  active: {
    background: "#f4f4f4",
  },
  content: {
    background: "#f9f9f9",
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  footer: {
    flex: 0,
  },

  appbar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const [drawerState, setDrawerState] = useState(false);
  const location = useLocation();
  const toggleDrawer = (open) => (event) => {
    console.log(`toggleDrawer ${open ? "true" : "false"}`);

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState(open);
  };

  useEffect(() => {
    setDrawerState(false);
  }, [location]);
  return (
    <div className={classes.root}>
      <MainAppBar
        offset={drawerState === true ? drawerWidth : 0}
        pageTitle={"page title"}
        burgerToggle={toggleDrawer}
      />
      <MainNav
        menuItems={menuItems}
        isOpen={drawerState}
        closeHandler={toggleDrawer}
      />

      <div className={classes.content}>
        <div className={classes.toolbar}></div>
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
