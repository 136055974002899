import LockOpenIcon from "@mui/icons-material/LockOpen";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import GroupIcon from "@mui/icons-material/Group";

export const menuItems = [
  {
    text: "Home",
    icon: HomeIcon,
    private: false,
    location: {
      pathname: "/",
    },
  },
  {
    text: "Profil",
    icon: PersonIcon,
    private: true,
    location: {
      pathname: "/profile",
    },
  },
  {
    text: "Modell",
    icon: DragIndicatorIcon,
    private: true,
    location: {
      pathname: "/model",
    },
  },
  {
    text: "Benutzer",
    icon: GroupIcon,
    private: true,
    admin: true,
    location: {
      pathname: "/users",
    },
  },
  {
    text: "Upload",
    icon: GroupIcon,
    private: true,
    admin: true,
    location: {
      pathname: "/upload",
    },
  },
];
