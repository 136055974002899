import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import UserList from "./UserList";
import NewUserForm from "./NewUserForm";
import { useData } from "../../services/api/use-data";
import { useAlert } from "../../context/Alert";
import { deleteUser } from "../../services/api/user";

const Users = () => {
  const { data, loading, error, retry } = useData("/admin/users");
  const [selection, setSelection] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { openAlert } = useAlert();

  const deleteSelectedUser = async () => {
    try {
      if (!selection[0]) {
        throw new Error("Bitte wählen Sie einen Eintrag aus der Liste");
      }
      setProcessing(true);
      await deleteUser(selection[0]);
      retry();
    } catch (err) {
      console.log(err);
      alert("Beim Löschen des Benutzers ist ein Fehler aufgetreten.");
    } finally {
      setProcessing(false);
    }
  };

  if (error) {
    return (
      <div>
        <h1>Fehler</h1>
        <p>{error}</p>
      </div>
    );
  }
  return (
    <div>
      <h1>Benutzer</h1>
      <Button
        onClick={() => {
          setDialogOpen(true);
        }}
      >
        Neu
      </Button>
      <Button
        onClick={() => {
          openAlert({
            title: "Benutzer löschen",
            description:
              "Möchten Sie den ausgewählten Benutzer wirklich löschen?",
          })
            .then(() => {
              deleteSelectedUser();
            })
            .catch((err) => {});
        }}
        disabled={selection.length === 0}
      >
        Löschen
      </Button>
      <UserList
        loading={loading || processing}
        users={data || []}
        onSelectionChange={(selection) => {
          setSelection(selection);
        }}
      />
      {/* {JSON.stringify(data)} */}

      <NewUserForm
        open={dialogOpen}
        onSuccess={() => {
          retry();
        }}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
    </div>
  );
};

export default Users;
