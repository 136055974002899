import { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import axios from "axios";
import _ from "lodash";

const apiPath = process.env.REACT_APP_API_PATH;
const auth = getAuth();

function useData(path, query = {}) {
  const url = `${apiPath}${path}`;
  console.log(url);
  const [data, setData] = useState(null);
  const [queryUsed, setQueryUsed] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shouldUpdate, setShouldUpdate] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        setError(null);
        const token = await auth.currentUser?.getIdToken(true);
        console.log({ token });
        const headers = {
          "Content-Type": "application/json",
          Authorization: token,
        };
        const response = await axios.get(url, { headers, params: query });
        setQueryUsed(query);
        setData(response.data);
      } catch (err) {
        console.log(err);
        setData(null);
        setError(err.message);
      } finally {
        setLoading(false);
        setShouldUpdate(false);
      }
    };
    if (shouldUpdate) {
      setShouldUpdate(false);
      getData();
    }
  }, [shouldUpdate]);

  useEffect(() => {
    setShouldUpdate(true);
  }, [url]);

  useEffect(() => {
    if (!_.isEqual(query, queryUsed)) {
      setShouldUpdate(true);
    }
  }, [query]);

  const retry = () => {
    setShouldUpdate(true);
  };

  return {
    data,
    loading,
    error,
    retry,
  };
}

export { useData };
