import axios from "axios";
import { getAuth } from "firebase/auth";
const apiPath = process.env.REACT_APP_API_PATH;
const auth = getAuth();

const getSignedUrl = async (fileName, fileType) => {
  console.log({ fileName, fileType });
  try {
    const token = await auth.currentUser?.getIdToken(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    const response = await axios.post(
      `${apiPath}/admin/get-signed-url`,
      { fileName, fileType },
      { headers }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export { getSignedUrl };
