import { useContext } from "react";
import { Button, Grid, Typography, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AuthContext } from "../../context/Auth";
import { useHistory } from "react-router";

const KButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  backgroundColor: "#b4aead",
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "auto",
  },

  gridContainer: {
    flexGrow: 1,
  },
}));

const AuthButton = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user, signOut, isAuthenticating } = useContext(AuthContext);

  if (isAuthenticating) return <></>;

  if (user) {
    return (
      <div className={classes.root}>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <Typography>{user.email}</Typography>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    signOut();
                    history.push("/");
                  }}
                >
                  Abmelden
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <Button
                  color="secondary"
                  textTransform="none"
                  variant="contained"
                  onClick={() => {
                    history.push("/signin");
                  }}
                >
                  Anmelden
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default AuthButton;
