import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  TextField,
  LinearProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { useContext } from "react";
import { AuthContext } from "../../context/Auth";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  dialogContent: {
    width: 240,
    minWidth: 240,
  },
}));

const ChangePasswordDialog = ({ open = false, onClose = () => {} }) => {
  const { changePassword } = useContext(AuthContext);
  const [processing, setProcessing] = useState(false);
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
    },
    validateOnChange: true,
    validateOnBlur: false,
    validate: (values) => {
      const errors = {};
      if (!values.oldPassword) {
        errors.oldPassword = "Feld erforderlich";
      }
      if (!values.newPassword) {
        errors.newPassword = "Feld erforderlich";
      } else if (values.newPassword?.length < 6) {
        errors.newPassword = "Das Passwort muss mindestens 6 Zeichen haben";
      }
      if (!values.repeatNewPassword) {
        errors.repeatNewPassword = "Feld erforderlich";
      } else {
        if (!(values.repeatNewPassword === values.newPassword)) {
          errors.repeatNewPassword = "Stimmt nicht überein";
        }
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        // alert(JSON.stringify(values));
        setProcessing(true);
        await changePassword(values.oldPassword, values.newPassword);
        onClose(true);
        formik.resetForm();
        // alert(
        //   "Ihr Passwort wurde erfolgreich geändert. Sie können Ihre neuen Zugangsdaten beim nächsten Login verwenden."
        // );
      } catch (err) {
        console.log(err);
        alert(err.message);
      } finally {
        setProcessing(false);
      }
    },
  });
  return (
    <Dialog open={open}>
      <DialogTitle>Passwort ändern</DialogTitle>
      <DialogContent>
        <Grid container>
          {processing ? (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          ) : undefined}
          <Grid item xs={12}>
            <TextField
              error={
                formik.touched.oldPassword && formik.errors.oldPassword
                  ? true
                  : false
              }
              helperText={
                formik.touched.oldPassword ? formik.errors.oldPassword : ""
              }
              required
              className={classes.textField}
              id="oldPassword"
              value={formik.values.oldPassword}
              label="Altes Passwort"
              variant="standard"
              type="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={
                formik.touched.newPassword && formik.errors.newPassword
                  ? true
                  : false
              }
              helperText={
                formik.touched.newPassword ? formik.errors.newPassword : ""
              }
              required
              className={classes.textField}
              id="newPassword"
              value={formik.values.newPassword}
              label="Neues Passwort"
              variant="standard"
              type="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={
                formik.touched.repeatNewPassword &&
                formik.errors.repeatNewPassword
                  ? true
                  : false
              }
              helperText={
                formik.touched.repeatNewPassword
                  ? formik.errors.repeatNewPassword
                  : ""
              }
              required
              className={classes.textField}
              id="repeatNewPassword"
              value={formik.values.repeatNewPassword}
              label="Passwort wiederholen"
              variant="standard"
              type="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={processing}
          onClick={() => {
            onClose();
            formik.resetForm();
          }}
        >
          Abbrechen
        </Button>
        <Button disabled={processing} onClick={formik.handleSubmit}>
          Passwort ändern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
