import axios from "axios";

const uploadFile = async (signedUrl, file) => {
  try {
    const headers = {
      "Content-Type": file.type,
    };
    console.log("uploading file.name");
    const response = await axios.put(signedUrl, file, { headers });
    console.log(response.data);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export { uploadFile };
