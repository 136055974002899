import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { Typography } from "@mui/material";

const useStyles = makeStyles({
  gridCell: {
    "&:focus-within": {
      outline: "none !important",
    },
  },
});

function CustomLoadingOverlay() {
  return (
    <GridOverlay
      sx={{
        backgroundColor: "rgba(255,0,0,1)",
        opacity: 1,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          minHeight: 100,
          zIndex: 20,
          backgroundColor: "rgba(249,249,249,1)",
          opacity: 1,
        }}
      >
        <LinearProgress />
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>Bitte haben Sie einen Moment Geduld...</Typography>
        </div>
      </div>
    </GridOverlay>
  );
}

const UserList = ({ users = [], onSelectionChange = () => {}, loading }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const classes = useStyles();
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      editable: false,
      headerClassName: classes.gridCell,
      cellClassName: classes.gridCell,
    },
    {
      field: "email",
      headerName: "E-Mail",
      width: 200,
      editable: false,
      headerClassName: classes.gridCell,
      cellClassName: classes.gridCell,
    },
    {
      field: "company",
      headerName: "Firma",
      width: 200,
      editable: false,
      headerClassName: classes.gridCell,
      cellClassName: classes.gridCell,
    },
  ];
  console.log(users);
  return (
    <DataGrid
      editMode="row"
      components={{
        LoadingOverlay: CustomLoadingOverlay,
      }}
      loading={loading}
      columns={columns}
      rows={users.map((user) => {
        return {
          id: user.uid,
          name: user.displayName,
          email: user.email,
          company: user.customClaims?.company || "",
        };
      })}
      disableColumnMenu={true}
      pageSize={20}
      rowsPerPageOptions={[3]}
      checkboxSelection
      SelectionUnit="FullRow"
      selectionModel={selectionModel}
      autoHeight
      disableSelectionOnClick
      onSelectionModelChange={(selection) => {
        if (selection.length > 1) {
          const newSelection = selection.filter((item) => {
            return selectionModel.find((element) => element === item)
              ? false
              : true;
          });
          setSelectionModel(newSelection);
          onSelectionChange(newSelection);
        } else {
          setSelectionModel(selection);
          onSelectionChange(selection);
        }
      }}
    ></DataGrid>
  );
};

export default UserList;
