import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/Auth";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Snackbar,
  Alert,
  Stack,
} from "@mui/material";
import ChangePasswordDialog from "./ChangePasswordDialog";
import { getAuth } from "firebase/auth";
const auth = getAuth();

const Profile = () => {
  const { user } = useContext(AuthContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [token, setToken] = useState(null);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  useEffect(() => {
    const getToken = async () => {
      try {
        const t = await auth.currentUser?.getIdToken(true);
        setToken(t);
      } catch (err) {
        setToken(null);
      }
    };
    if (user) {
      getToken();
    }
  }, [user]);
  return (
    // <div style={{ width: "100%" }}>
    <Stack spacing={2} sx={{ width: "100%" }}>
      <h1>Profil</h1>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ width: 100 }}>
              <strong>Name</strong>
            </TableCell>
            <TableCell>{user.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: 100 }}>
              <strong>E-Mail</strong>
            </TableCell>
            <TableCell>{user.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: 100 }}>
              <strong>Passwort</strong>
            </TableCell>
            <TableCell>
              *****{" "}
              <span
                style={{
                  fontSize: "0.8em",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setDialogOpen(true)}
              >
                Ändern
              </span>
            </TableCell>
          </TableRow>
          {user.isAdmin() ? (
            <TableRow>
              <TableCell sx={{ width: 100 }}>
                <strong>Rolle</strong>
              </TableCell>
              <TableCell>Administrator</TableCell>
            </TableRow>
          ) : undefined}
          {/* <TableRow>
            <TableCell sx={{ width: 100 }}>
              <strong>Token</strong>
            </TableCell>
            <TableCell>
              {token
                ? `${token.slice(0, 5)}...${token.slice(
                    token.length - 5,
                    token.length
                  )}`
                : ""}
              <Button onClick={() => navigator.clipboard.writeText(token)}>
                Copy
              </Button>
            </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>

      <ChangePasswordDialog
        open={dialogOpen}
        onClose={(success) => {
          setDialogOpen(false);
          if (success === true) {
            setSnackBarOpen(true);
          }
        }}
      />
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        message="Passwortänderung erfolgreich"
        onClose={() => {
          setSnackBarOpen(false);
        }}
        sx={{ width: "97vw" }}
        // action={action}
      >
        <Alert
          onClose={() => {
            setSnackBarOpen(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Ihr Passwort wurde erfolgreich geändert. Sie können Ihre neuen
          Zugangsdaten beim nächsten Login verwenden.
        </Alert>
      </Snackbar>
      {/* {JSON.stringify(user)} */}
    </Stack>

    // {/* </div> */}
  );
};

export default Profile;
