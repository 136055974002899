import { createContext, useEffect, useReducer } from "react";
import authService from "../services/auth";
export const AuthContext = createContext();

const authReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        isInitializing: false,
        authError: null,
        isAuthenticating: false,
        user: action.payload.user,
        decodedUser: action.payload.decodedUser
          ? {
              ...action.payload.decodedUser,
              name: action.payload.decodedUser?.claims?.name,
              email: action.payload.user?.email,
              isAdmin: () => {
                return (
                  action.payload.decodedUser?.claims?.roles &&
                  action.payload.decodedUser?.claims?.roles.find(
                    (item) => item === "admin"
                  )
                );
              },
            }
          : null,
      };
    case "SET_ERROR":
      return {
        ...state,
        authError: action.payload,
        isAuthenticating: false,
        isInitializing: false,
        user: null,
        decodedUser: null,
      };
    case "SET_AUTHENTICATING":
      return {
        ...state,
        isInitializing: false,
        isAuthenticating: action.payload,
      };
    case "SIGNUP":
    case "SIGNIN":
    case "SIGNOUT":
      return {
        ...state,
        user: null,
        decodedUser: null,
        authError: null,
        isInitializing: false,
        isAuthenticating: true,
      };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    isInitializing: true,
    user: null,
    decodedUser: null,
    authError: null,
    isAuthenticating: false,
  });
  const { user, decodedUser, authError, isAuthenticating, isInitializing } =
    state;

  useEffect(() => {
    return authService.onAuthStateChanged((user) => {
      const getTokenResult = async () => {
        try {
          const tokenResult = await user.getIdTokenResult();
          dispatch({
            type: "SET_USER",
            payload: { user, decodedUser: tokenResult },
          });
        } catch (err) {
          console.log(err);
          dispatch({
            type: "SET_USER",
            payload: {
              user: null,
              decodedUser: null,
            },
          });
        }
      };
      if (user) {
        getTokenResult();
      } else {
        dispatch({
          type: "SET_USER",
          payload: {
            user: null,
            decodedUser: null,
          },
        });
      }
      console.log("onAuthStateChanged");
      console.log({ user });
    });
  }, []);

  const signUp = async (email, password) => {
    try {
      dispatch({ type: "SIGNUP" });
      await authService.createUserWithEmailAndPassword(email, password);
    } catch (err) {
      console.error(err);
      dispatch({ type: "SET_ERROR", payload: err.message });
    } finally {
      dispatch({ type: "SET_AUTHENTICATING", payload: false });
    }
  };

  const signIn = async (email, password) => {
    try {
      dispatch({ type: "SIGNIN" });
      await authService.signInWithEmailAndPassword(email, password);
    } catch (err) {
      console.error(err);
      dispatch({ type: "SET_ERROR", payload: err.message });
    } finally {
      dispatch({ type: "SET_AUTHENTICATING", payload: false });
    }
  };

  const signOut = async () => {
    try {
      dispatch({ type: "SIGNOUT" });
      await authService.signOut();
    } catch (err) {
      console.error(err);
      dispatch({ type: "SET_ERROR", payload: err.message });
    } finally {
      dispatch({ type: "SET_AUTHENTICATING", payload: false });
    }
  };

  const sendPasswordResetEmail = async (email) => {
    try {
      return await authService.sendPasswordResetEmail(email);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const confirmPasswordReset = async (code, password) => {
    try {
      return await authService.confirmPasswordReset(code, password);
    } catch (err) {
      console.log(err);
    }
  };

  const changePassword = async (oldPassword, newPassword) => {
    try {
      if (!user) {
        throw new Error("User not signed in");
      }
      await authService.reauthenticateWithCredential(user, oldPassword);
      await authService.updatePassword(user, newPassword);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const isAdmin = decodedUser?.claims?.roles
    ? decodedUser.claims.roles.findIndex((role) => role === "admin") >= 0
    : false;

  return (
    <AuthContext.Provider
      value={{
        user: decodedUser,
        isInitializing,
        isAuthenticating,
        authError,
        isAdmin,
        signUp,
        signIn,
        signOut,
        sendPasswordResetEmail,
        changePassword,
        confirmPasswordReset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
