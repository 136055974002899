import { createTheme } from "@mui/material/styles";

const defaultFont = "Open Sans, sans-serif";
const serifHeadingFont = "Bitter, serif";
const headingFont = "Open Sans, sans-serif";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#141606",
      light: "#78909C"
    },
    secondary: {
      main: "#e9e9e9"
    },
    button: {
      primary: {
        main: "#e9e9e9"
      },
      secondary: {
        main: "#141606"
      }
    },
    text: {
      primary: "#141606",
      secondary: "141606"
    },
    highlight: "blue"
  },
  typography: {
    fontFamily: defaultFont,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontFamily: headingFont,
      fontSize: "2em"
    },
    h2: {
      fontFamily: headingFont,
      fontSize: "1.8em"
    },
    h3: {
      fontFamily: serifHeadingFont,
      fontSize: "1.6em"
    },
    h4: {
      fontFamily: headingFont,
      fontSize: "1.4em"
    },
    h5: {
      fontFamily: headingFont,
      fontSize: "1.2em"
    },
    h6: {
      fontFamily: headingFont,
      fontSize: "1.1em"
    },
    subtitle1: {
      fontFamily: headingFont
    },
    subtitle2: {
      fontFamily: headingFont
    }
  }
});
