import { useContext, useEffect, useState } from "react";
import { Button, TextField, Paper, Typography } from "@mui/material";
import { Formik } from "formik";
import { AuthContext } from "../../context/Auth";
import { useStyles } from "./styles";
import { Redirect, Link, useLocation, useHistory } from "react-router-dom";
import urlencode from "urlencode";
import queryString from "query-string";
import validator from "validator";

const ForgotPassword = () => {
  const classes = useStyles();
  const { user, isAuthenticating, sendPasswordResetEmail, authError } =
    useContext(AuthContext);
  const [loadingState, setLoadingState] = useState({
    loading: false,
    error: null,
  });

  const history = useHistory();
  const location = useLocation();

  const q = queryString.parse(location.search);
  const showConfirmation = q.confirmation === "true";

  if (user || isAuthenticating === true) {
    console.log("redirecting to Home");
    console.log({ user, isAuthenticating });
    return <Redirect to="/" />;
  }

  if (showConfirmation) {
    return (
      <Paper elevation={6} className={classes.root}>
        <Typography variant="h4">E-Mail verschickt</Typography>
        <div className={classes.content}>
          <Typography>
            Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres
            Passwortes geschickt. Wenn Sie Ihr Passwort zurückgesetzt haben,
            wählen Sie "Zur Anmeldung", um sich mit Ihrem neuen Passwort
            anzumelden.
          </Typography>
          <Button
            variant="contained"
            className={classes.button}
            color="secondary"
            disabled={isAuthenticating}
            onClick={() => {
              history.push("/signin");
            }}
          >
            Zum Login
          </Button>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to="/forgotpassword">
              <Typography variant="caption">
                Keine E-Mail bekommen? Erneut versuchen.
              </Typography>
            </Link>
          </div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper elevation={6} className={classes.root}>
      <Typography variant="h4">Passwort zurücksetzen</Typography>
      <div className={classes.content}>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={async (values) => {
            try {
              setLoadingState({ loading: true, error: false });
              await sendPasswordResetEmail(values.email);
              history.push(`${location.pathname}/?confirmation=true`);
              setLoadingState({ loading: false, error: false });
            } catch (err) {
              setLoadingState({ loading: false, error: err.description });
              console.log(err);
            }
          }}
          validateOnChange={false}
          validate={(values) => {
            const errors = {};
            if (!values.email || values.email.length === 0) {
              errors.email = "Eingabe erforderlich";
            } else {
              if (!validator.isEmail(values.email)) {
                errors.email = "Ungültige E-Mail Adresse";
              }
            }
            return errors;
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <>
                <Typography>
                  Bitte geben Sie Ihre E-Mail-Adresse ein und wählen Sie
                  anschließend "Abschicken". Wir senden Ihnen dann einen Link
                  zu, über den Sie Ihr Passwort neu vergeben können.
                </Typography>
                <TextField
                  label="E-Mail"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  inputProps={{ className: classes.input }}
                  helperText={touched.email && errors.email ? errors.email : ""}
                  error={touched.email && errors.email ? true : false}
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                />

                <Button
                  variant="contained"
                  className={classes.button}
                  color="secondary"
                  disabled={!validator.isEmail(values.email)}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Abschicken
                </Button>
              </>
            );
          }}
        </Formik>
        {loadingState.error}
      </div>
    </Paper>
  );
};

export default ForgotPassword;
