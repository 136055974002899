import { useContext } from "react";
import { AuthContext } from "../../context/Auth";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

const Home = () => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  return (
    <div>
      <h1>Home</h1>
      <p>Willkommen beim Kolumba Bestatter-Tool</p>
      {user ? undefined : (
        <>
          <p>Sie müssen sich anmelden, um die Anwendung nutzen zu können.</p>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              history.push("/signin");
            }}
          >
            Zur Anmeldung
          </Button>
        </>
      )}
    </div>
  );
};

export default Home;
