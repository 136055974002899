import { Switch } from "react-router-dom";
import RouteWithLayout from "./RouteWithLayout";
import SignUp from "../pages/Auth/SignUp";
import SignIn from "../pages/Auth/SignIn";
import Profile from "../pages/Profile";
import Home from "../pages/Home";
import Users from "../pages/Users";
import Model from "../pages/Model";
import AuthLayout from "../layout/AuthLayout";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import PageNotFound from "../pages/PageNotFound";
import Upload from "../pages/Upload";

const MainRoutes = () => {
  return (
    <Switch>
      <RouteWithLayout exact path="/">
        <Home />
      </RouteWithLayout>
      <RouteWithLayout layoutComponent={AuthLayout} path="/signup">
        <SignUp />
      </RouteWithLayout>
      <RouteWithLayout layoutComponent={AuthLayout} path="/signin">
        <SignIn />
      </RouteWithLayout>
      <RouteWithLayout layoutComponent={AuthLayout} path="/forgotpassword">
        <ForgotPassword />
      </RouteWithLayout>
      <RouteWithLayout auth="private" path="/profile">
        <Profile />
      </RouteWithLayout>
      <RouteWithLayout auth="private" path="/model">
        <Model />
      </RouteWithLayout>
      <RouteWithLayout auth="admin" path="/users">
        <Users />
      </RouteWithLayout>
      <RouteWithLayout auth="admin" path="/upload">
        <Upload />
      </RouteWithLayout>
      <RouteWithLayout path="*">
        <PageNotFound />
      </RouteWithLayout>
    </Switch>
  );
};

export default MainRoutes;
