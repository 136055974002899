import "./App.css";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { theme } from "./styles/theme";
import { AuthContextProvider } from "./context/Auth";
import { AlertContext, AlertContextProvider } from "./context/Alert";
import CssBaseline from "@mui/material/CssBaseline";
import MainRoutes from "./routes/MainRoutes";

function App() {
  return (
    <>
      <CssBaseline />
      <AlertContextProvider>
        <AuthContextProvider>
          <ThemeProvider theme={theme}>
            <Router>
              <MainRoutes />
            </Router>
          </ThemeProvider>
        </AuthContextProvider>
      </AlertContextProvider>
    </>
  );
}

export default App;
