import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  confirmPasswordReset,
  reauthenticateWithCredential,
  signOut,
  browserSessionPersistence,
  EmailAuthProvider,
} from "firebase/auth";

initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const auth = getAuth();
setPersistence(auth, browserSessionPersistence);

const authService = {
  onAuthStateChanged: (callback) => {
    onAuthStateChanged(auth, callback);
  },
  createUserWithEmailAndPassword: async (email, password) => {
    return await createUserWithEmailAndPassword(auth, email, password);
  },
  signInWithEmailAndPassword: async (email, password) => {
    return await signInWithEmailAndPassword(auth, email, password);
  },
  sendPasswordResetEmail: async (email) => {
    return await sendPasswordResetEmail(auth, email);
  },
  confirmPasswordReset: async (code, password) => {
    return await confirmPasswordReset(auth, code, password);
  },
  updatePassword: async (user, newPassword) => {
    return await updatePassword(user, newPassword);
  },
  reauthenticateWithCredential: async (user, password) => {
    const credential = EmailAuthProvider.credential(user.email, password);
    return await reauthenticateWithCredential(user, credential);
  },
  signOut: async () => {
    return await signOut(auth);
  },
};

export default authService;
