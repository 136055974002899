import { useContext, useState } from "react";
import {
  Drawer,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

import { makeStyles, styled } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/Auth";

const defaultDrawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: (props) => props.drawerWidth,
  },
  drawerPaper: {
    width: (props) => props.drawerWidth,
  },
  active: {
    backgroundColor: "#000000",
    color: "orange",
    fontWeight: "bold",
  },
  title: {
    padding: theme.spacing(2),
    fontWeight: "bold",
    fontFamily: "Bitter",
  },
  mItem: {
    fontWeight: "bold",
    fontFamily: "Bitter, serif",
  },
}));

const MainNav = ({
  menuItems,
  drawerWidth = defaultDrawerWidth,
  isOpen,
  closeHandler,
}) => {
  const classes = useStyles({ drawerWidth });
  const history = useHistory();
  const location = useLocation();
  const { user, isAdmin } = useContext(AuthContext);

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      open={isOpen}
      onClose={closeHandler(false)}
      onBackdropClick={closeHandler(false)}
      hideBackdrop={false}
    >
      <div>
        {/* <h3 className={classes.title}>
          Kolumba
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;Bestatter-Tool
        </h3> */}
        <Typography variant="h3" className={classes.title}>
          Kolumba
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;Bestatter-Tool
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <List component="nav" aria-label="main-nav">
            {menuItems.map((item, index) => {
              const locked = !user && item.private === true;
              const selected = location.pathname === item.location.pathname;
              const Icon = locked ? LockIcon : item.icon;
              if (item.admin && !isAdmin) {
                return undefined;
              }
              return (
                <ListItemButton
                  disabled={locked}
                  key={index}
                  onClick={() => {
                    console.log("menu click");
                    closeHandler(false);
                    history.push(item.location);
                  }}
                  selected={selected}
                >
                  <ListItemIcon>
                    {" "}
                    <Icon
                      style={{
                        color: selected ? "primary.main" : "primary.light",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.mItem}
                    style={{
                      color: selected ? "primary.main" : "primary.light",
                    }}
                    primary={item.text}
                  />
                </ListItemButton>
              );
            })}
          </List>
        </div>
        <div style={{ marginTop: "auto" }}>
          <Typography variant="caption">{`Environment: ${process.env.REACT_APP_ENVIRONMENT}`}</Typography>
        </div>
      </div>
    </Drawer>
  );
};

export default MainNav;
