import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    minWidth: 380,
    width: 380,
    height: 420,
    minHeight: 420,
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    rowGap: "1.2em",
    flexGrow: 1,
    width: "100%",
    minWidth: "100%",
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
  },
  textField: {},
  button: {
    marginTop: "0.2em",
  },
}));
