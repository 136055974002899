import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Typography,
  CircularProgress,
  Stack,
  Chip,
  Button,
} from "@mui/material";
import { useData } from "../../services/api/use-data";
import { AuthContext } from "../../context/Auth";
import urlencode from "urlencode";

const Model = () => {
  const { data, loading, error, retry } = useData("/occupancy");
  const [selection, setSelection] = useState([]);
  const [modelInitialized, setModelInitialized] = useState(false);
  const modelRef = useRef();
  const { user } = useContext(AuthContext);

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  useEffect(() => {
    if (modelInitialized && modelRef.current?.contentWindow) {
      modelRef.current.contentWindow.postMessage(
        JSON.stringify({
          action: "SET_OCCUPANCY",
          payload: data.map((item) => {
            return {
              ...item,
              name: `${item.corner}-${item.stele.toString().padStart(2, "0")}${
                item.flags.exposed ? "E" : ""
              }-${item.grave.toString().padStart(2, "0")}`,
            };
          }),
        })
      );
    }
  }, [data, modelInitialized, modelRef.current]);
  useEffect(() => {
    if (modelInitialized && modelRef.current?.contentWindow) {
      modelRef.current.contentWindow.postMessage(
        JSON.stringify({ action: "SET_SELECTION", payload: selection })
      );
    }
  }, [selection, modelInitialized, modelRef.current]);

  window.onmessage = (e) => {
    try {
      if (selection.length > 4) {
        return;
      }
      const m = JSON.parse(e.data);
      const existingElement = selection.findIndex((item) => item === m.fach);
      if (existingElement > -1) {
        onDeleteSelectedItem(existingElement);
      } else {
        setSelection([...selection, m.fach]);
      }
    } catch (err) {
      console.log(err);
      console.log("could not read message from iframe");
    }
  };

  const onDeleteSelectedItem = (index) => {
    setSelection([...selection.slice(0, index), ...selection.slice(index + 1)]);
  };

  if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <Stack sx={{ marginBottom: 2 }} direction="row" spacing={1}>
        <Typography>Auswahl: </Typography>
        {selection.map((item, index) => {
          return (
            <Chip
              key={`selection-${index}`}
              label={item}
              onDelete={() => {
                onDeleteSelectedItem(index);
              }}
            />
          );
        })}
        {selection.length > 0 ? (
          <Button
            sx={{ textTransform: "none" }}
            variant="contained"
            color="secondary"
            onClick={() => {
              window.open(
                `mailto:kolumbarium-rheinkirche.de?subject=${urlencode(
                  "Anfrage aus dem Kolumba Bestatter-Tool"
                )}&body=${urlencode(`Liebes Kolumba-Team,
ich bitte um Reservierung folgender Urnenfächer:
${selection.reduce(
  (prev, cur) => `${prev}
${cur}`,
  ""
)}

Mit freundlichen Grüßen,
${user?.claims?.name || ""}
${user?.claims?.company || ""}
                `)}`
              );
            }}
          >
            Anfragen
          </Button>
        ) : undefined}
      </Stack>

      <iframe
        ref={modelRef}
        src="/kolumb/"
        scrolling="no"
        title="model"
        style={{
          flexGrow: 1,
          width: "100%",
          height: "100%",
          border: "none",
        }}
        onLoad={() => {
          setModelInitialized(true);
        }}
      />
    </>
  );
};

export default Model;
