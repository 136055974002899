import { useState, useRef } from "react";
import {
  Button,
  Input,
  LinearProgress,
  IconButton,
  Card,
  CardContent,
  Stack,
  Link,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { getSignedUrl } from "../../services/api/get-signed-url";
import { uploadFile } from "../../services/api/upload-file";
import { useData } from "../../services/api/use-data";
import ReplayIcon from "@mui/icons-material/Replay";
import ClearIcon from "@mui/icons-material/Clear";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LogList from "./LogList";
import { useAlert } from "../../context/Alert";
import "./animation.css";

const Upload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const { data, loading, error, retry } = useData("/logs");
  const { openAlert } = useAlert();

  const upload = async () => {
    try {
      setProcessing(true);
      const fileParts = selectedFile.name.split(".");
      const fileType = fileParts[1];
      const signedUrl = await getSignedUrl("occupancy.csv", fileType);
      await uploadFile(signedUrl.signedUrl, selectedFile);
      setUploadComplete(true);
    } catch (err) {
      console.log(err);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div>
      <h1>Upload Belegungsliste</h1>
      <p>
        Hier können Sie die aktuelle Belegungsliste als CSV-Datei hochladen.
      </p>
      <div style={{ display: "flex", justifyContent: "start" }}>
        <Card sx={{ width: 420 }}>
          <CardContent>
            <Stack spacing={1}>
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 80,
                  border: "2px dotted black",
                  borderRadius: 5,
                }}
              >
                <label htmlFor="file-to-upload" style={{ display: "block" }}>
                  <Input
                    sx={{ display: "none" }}
                    inputProps={{ accept: ".csv" }}
                    id="file-to-upload"
                    type="file"
                    onChange={(e) => {
                      setSelectedFile(e.target.files[0]);
                      e.target.value = null;
                    }}
                  />
                  {selectedFile ? (
                    <Typography sx={{ position: "relative" }}>
                      {selectedFile.name}{" "}
                      {uploadComplete ? (
                        <CheckCircleIcon
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            marginLeft: 1,
                            color: "green",
                          }}
                        />
                      ) : undefined}
                    </Typography>
                  ) : (
                    <Link component="span" style={{ cursor: "pointer" }}>
                      Datei auswählen
                    </Link>
                  )}
                </label>

                {selectedFile ? (
                  <div
                    style={{
                      height: "100%",
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        setSelectedFile(null);
                        setUploadComplete(false);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                ) : undefined}
                {uploadComplete ? (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="caption">
                      Überprüfen Sie den Importstatus unter "Letzte Importe"
                    </Typography>
                  </div>
                ) : undefined}
              </div>

              <Button
                sx={{ height: 32 }}
                variant="contained"
                disabled={!selectedFile || processing || uploadComplete}
                color="secondary"
                onClick={() => {
                  upload();
                }}
              >
                {processing ? <CircularProgress size={20} /> : "Hochladen"}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </div>

      <h3>
        Letzte Importe{" "}
        <IconButton
          sx={
            uploadComplete
              ? {
                  animationDuration: ".3s",
                  animationName: "highlight-reload-button",
                  animationDirection: "alternate",
                  animationIterationCount: 6,
                  animationDelay: "0.8s",
                }
              : {}
          }
        >
          <ReplayIcon onClick={() => retry()} />
        </IconButton>
      </h3>
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <p>Fehler beim Laden der Prozessliste</p>
      ) : (
        <LogList logs={data.Items} />
      )}
    </div>
  );
};

export default Upload;
